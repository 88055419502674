<template>
    <div v-loading.fullscreen.lock="loading">
        <currency-search-bar-component
            add_auth="新增印章使用申请"
            :all_currency_category="all_currency_category"
            :all_enterprise="all_enterprise"
            :all_staff="all_staff"
            @search="get_currency_index"
            @addTeam="addTeam"
        ></currency-search-bar-component>
        <common-table-component
            details_auth="印章使用申请详情"
            :col_arr="fcol_arr"
            :table_data="ftable_data"
            @details_row="details_currency"
            table_height="549px"
        ></common-table-component>
        <common-page-component
            :current_page="cond.page"
            :page_size="cond.limit"
            :total="ftotal"
            @search="get_currency_index"
        ></common-page-component>
        <currency-edit-component
            :id="fid"
            :show_type="edit_type"
            :dialogFormVisible="fdialogFormVisible"
            :currency_details_data="currency_details_data"
            :enterprise_department="enterprise_department"
            :all_currency_category="all_currency_category"
            :all_staff="all_staff"
            :edit_auth="edit_auth"
            :del_auth="del_auth"
            :add_file_auth="add_file_auth"
            :to_approval_auth="to_approval_auth"
            :sel_approval_auth="sel_approval_auth"
            :ApprovalAuth="ApprovalAuth"
            :withdraw_auth="withdraw_auth"
            print_auth="打印印章使用申请"
            @show_edit="show_edit"
            @addTeam="addTeam"
            @search="get_currency_index"
            @exitDialog="dialogExit"
            @frefresh="frefresh"
            @details_row="details_currency"
        ></currency-edit-component>
    </div>
</template>

<script>
import { enterprise_department_request } from '@/network/enterprise.js'
import { currency_category_list_request,staff_list_request,enterprise_list_request } from '@/network/list.js'
import { currency_index_request,currency_details_request} from '@/network/finance/currency.js'
import { thousandBitSeparator,fomatFloat} from '@/assets/js/common.js'

import CurrencySearchBarComponent from '@/components/finance/currency/CurrencySearchBarComponent'
import CommonTableComponent from '@/components/common/CommonTableComponent'
import CommonPageComponent from '@/components/common/CommonPageComponent'
import CurrencyEditComponent from '@/components/finance/currency/CurrencyEditComponent'

export default {
    name:'',
    data(){
        return {
            fid: 0,
            edit_type: 0,
            loading: false,
            fdialogFormVisible: false,
            cond: {
                name: '',
                state: '',
                category: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                page: 1,
                limit: 10,
            },
            ftotal: 0,
            ftable_data: [],
            fcol_arr: [
                {
                    prop: 'apply_date',
                    label: '申请日期',
                    minWidth: '100px',
                },
                {
                    prop: 'name',
                    label: '申请单号',
                    minWidth: '120px'
                },
                {
                    prop: 'state',
                    label: '审批状态',
                    minWidth: '100px',
                    tag: (d) => {
                        if(d===0) {
                            return {
                                type: 'info',
                                filter_data: '待提交'
                            }
                        } else if(d===1) {
                            return {
                                type: '',
                                filter_data: '审批中'
                            }
                        } else if(d===2) {
                            return {
                                type: 'success',
                                filter_data: '已通过'
                            }
                        } else if(d===3) {
                            return {
                                type: 'danger',
                                filter_data: '已驳回'
                            }
                        }
                    }
                },
                {
                    prop: 'category_name',
                    label: '申请类型',
                    minWidth: '150px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'title',
                    label: '申请标题',
                    minWidth: '300px',
                    show_overflow_tooltip: true
                },
                {
                    prop: 'current_examine_staff_name',
                    label: '当前审批人',
                    minWidth: '100px',
                },
                {
                    prop: 'staff_name',
                    label: '申请人',
                    minWidth: '100px',
                },
                {
                    prop: 'department_name',
                    label: '申请部门',
                    minWidth: '120px'
                },
                {
                    prop: 'enterprise_name',
                    label: '申请企业',
                    minWidth: '260px'
                }
            ],
            currency_details_data: {},
            enterprise_department: [],
            all_currency_category: [],
            all_enterprise: [],
            all_staff: [],
            edit_auth: false,
            del_auth: false,
            //补充附件
            add_file_auth: false,
            //提交
            to_approval_auth: false,
            //查看审批
            sel_approval_auth: false,
            //审批
            ApprovalAuth: false,
            //撤回审批
            withdraw_auth: false
        }
    },
    computed:{},
    methods:{
        get_currency_index(param = {}) {
            this.loading = true
            this.cond.name = param.name ?? this.cond.name
            this.cond.state = param.state ?? this.cond.state
            this.cond.category = param.category ?? this.cond.category
            this.cond.enterprise_id = param.enterprise_id ?? this.cond.enterprise_id
            this.cond.department_id = param.department_id ?? this.cond.department_id
            this.cond.staff_id = param.staff_id ?? this.cond.staff_id
            this.cond.page = param.page ?? this.cond.page
            if (param.limit) {
                this.cond.limit = param.limit
                this.cond.page = 1
            }

            currency_index_request(this.cond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.ftable_data = s.result.data
                        this.ftotal = s.result.total
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        details_currency(id) {
            this.fid = id
            this.fdialogFormVisible = true
            currency_details_request(id)
                .then((s) => {
                    if (s.status === 0) {
                        this.currency_details_data = s.result
                        this.currency_details_data.department_id = [s.result.enterprise_id,s.result.department_id]
                        //权限判断
                        this.is_auth(s.result)
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        addTeam() {
            this.edit_type = 1
            this.fdialogFormVisible = true
        },
        show_edit() {
            this.edit_type = 2
        },
        dialogExit() {
            this.fdialogFormVisible = false
            this.fid = 0
            this.edit_type = 0
        },
        get_enterprise_department() {
            this.loading = true
            enterprise_department_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.enterprise_department = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_currency_category() {
            this.loading = true
            currency_category_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.all_currency_category = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_enterprise() {
            this.loading = true
            enterprise_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        get_all_staff() {
            this.loading = true
            staff_list_request()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        frefresh() {
            this.details_currency(this.fid)
            this.get_currency_index()
        },
        is_auth(res) {
            this.edit_auth = false
            this.del_auth = false
            this.add_file_auth = false
            this.to_approval_auth = false
            this.sel_approval_auth = false
            this.ApprovalAuth = false
            this.withdraw_auth = false

            if(res.state === 0) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交印章使用申请')
                    this.edit_auth = this.$_has('修改印章使用申请')
                    this.del_auth = this.$_has('删除印章使用申请')
                }
            } else if(res.state === 1) {
                 //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.withdraw_auth = this.$_has('撤回印章使用申请')
                    this.add_file_auth = this.$_has('补充印章使用申请附件')
                }
                //判断当前审批人是否为自己
                if(res.current_examine_staff_id === this.$store.state.user.id) {
                    this.ApprovalAuth = this.$_has('审批印章使用申请')
                }
                this.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
            } else if(res.state === 2) {
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.add_file_auth = this.$_has('补充印章使用申请附件')
                }
                this.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
            } else if(res.state === 3) {
                //判断创建人或申请人是否为自己
                if((res.staff_id === this.$store.state.user.id) || (res.create_staff === this.$store.state.user.id)) {
                    this.to_approval_auth = this.$_has('提交印章使用申请')
                    this.edit_auth = this.$_has('修改印章使用申请')
                    this.del_auth = this.$_has('删除印章使用申请')
                }
                this.sel_approval_auth = this.$_has('查看印章使用申请审批记录')
            }
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_enterprise_department()
        this.get_all_currency_category()
        this.get_all_enterprise()
        this.get_all_staff()
        this.get_currency_index()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        CurrencySearchBarComponent,
        CommonTableComponent,
        CommonPageComponent,
        CurrencyEditComponent
    },
    watch:{}
}
</script>

<style lang='less'></style>